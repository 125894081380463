.link{
    margin: 10px;
    color: #1D1D1B;
    font-family: 'Arial';
    font-size: 2.5vh;
}
.link a{
    color: #1D1D1B;
    text-decoration: none;

}
.link a:hover{
    color: rgba(0, 0, 255, 0.781);
    cursor: pointer;
    padding-bottom: 12px;
    border-bottom: 3px solid rgba(0, 0, 255, 0.781);

}

.link .active {
    padding-bottom: 12px;
    border-bottom: 3px solid rgba(0, 0, 255, 0.781);
}