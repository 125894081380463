.tools-wrapper {
    position: relative;
    display: block;
}

.cubes {
    display: flex;
    flex-direction: row;
}
.tools {
    height: 90px;
    background: #2F2F2F;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
}

.tools svg{
    margin-left: 2vw;
    margin-right: 2vw;
    width: 80px;
    height: 60px;
    fill: none;
}
.tools svg {
    filter: grayscale(100%); 
    transition: filter 0.3s; 
}

.tools svg:hover {
    filter: grayscale(0%); 
}
@media screen and (max-width: 1024px) {
    .tools{
        display: grid;
        align-items: center;
        justify-items: center;
        grid-template-columns: repeat(4, 4fr);
        height: 100% ;}
    .tools svg{
        width: 70px;
        height: 50px;
        fill: none;
        filter: grayscale(0%); 
        margin: 10px 0;}
}

@media screen and (max-width: 768px) {
    .tools svg{
        width: 60px;
        height: 40px;
        fill: none;
        }
}

@media screen and (max-width: 425px) {
    .tools{
        display: grid;
        align-items: center;
        justify-items: center;
        grid-template-columns: repeat(4, 4fr);
        padding: 26px;}
    .tools svg {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;}
}
