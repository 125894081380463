.blue {
    color: #0081F8;
}

.cian {
    color: #9AECFE;
}

.introduce {
    height: calc(100vh - 100px);
    position: relative;
    margin-top: 80px;
}
.introduce img{
    width: 96vw;
    margin: 0 2vw;
}

.particles {
    width: 100%;
    height: calc(40vh + 20vw);
    transform: translateZ(0px);
}

.hide {
    display: none;
}

.text {
    font-size: calc(100vw / 12.5);
    font-family: 'Arial';
    text-align: end;
    position: absolute;
    top: 65px;
    right: 80px;
}

.subtext {
    width: 39%;
    font-size: calc(100vw / 50);
    font-family: 'Arial';
    text-align: start;
    position: absolute;
    top: 38%;
    left: 2%;
}
/* .big {
    font-size: 150px;
    font-family: 'Arial';
    text-align: end;
} */

@media screen and (max-width: 1350px) {
    .subtext {
     top: 36%;
     display: none;
    }
    .introduce {
        height: calc(60vh );
    }
}

@media screen and (max-width: 1200px) {
    .subtext {
        top: 40%;
    }

    .introduce {
        height: calc(40vh );
    }

    .particles {
        height: calc(30vh);
    }
}

@media screen and (max-width: 970px) {
    .subtext {
        top: 32%;
    }
  
    .particles {
        height: calc(25vh);
    }
}


@media screen and (max-width: 700px) {
    .subtext {
        top: 26%;
    }
    .particles {
        display: none;
    }
}

@media screen and (max-width: 560px) {
    .subtext {
        top: 22%;
    }
    .particles {
        display: none;
    }
}

@media screen and (max-width: 430px) {
    .subtext {
        top: 22%;
        display: none;
    }
    .text {
        font-size: calc(100vw / 10.7);
        right: 20px;
    }
    .introduce {
        height: 230px;
        top: 0px;
        margin-top: 0;
        position: relative;
    }
    .particles {
        display: none;
    }
    
}
