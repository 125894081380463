.ProjectDetails {
    padding-top: 90px;
    overflow: hidden;
}

.sectionContainer {
    margin: auto;
    width: 81vw;
}

.product {
    padding: 0 7%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    position: relative;
}

.product .imageContainer {
    display: flex;
    gap: 100px;
}

.product .imageContainer img{
    height: 700px;
}

.projectIfon {
    display: flex;
    flex-direction: column;
    width: 45vw;
}

.projectIfon h1 {
    margin-top: 0;
    font-size: 70px;
    font-weight: 500;
}

.projectIfon h2 {
    font-size: 48px;
    font-weight: 600;
    margin-top: 0;
}

.projectIfon p {
    font-size: 24px;
}

.client {
    display: flex;
    width: 80vw;
    margin: auto;
    justify-content: space-between;
    gap: 100px;
    margin: 200px auto;
}

.client .Title .zaglav{
    left: 20%;
}

.client .Title hr {
    width: 100%;
    margin: 20px 0;
}

.clentInfo {
    width: 50vw;
    position: relative;
}

.client p {
    font-size: 24px;
    color: #666;
}

.client img {
    width: 400px;
    height: 430px;
}

.clentInfo .sectionContainer {
    width: 50vw;
}

.clentInfo .sectionContainer p{
    width: 50vw;
}

.product {
    margin-bottom: 60px;
}

.product .tags {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding-top: 50px;
    width: fit-content;
}

.product span {
    font-size: 30px;
    color: white;
    background-color: #7177ff;
    padding: 10px 20px;
    border-radius: 16px;
    text-align: center;
    width: fit-content;
}

.client .sectionContainer {
    padding-top: 10px;
    width: 80vw;
}

.solutionItem {
    border: 1px solid black;
    border-radius: 12px;
    padding: 10px 70px;
}

.solutionGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 2rem;
}

.solution .sectionContainer {
    padding-top: 40px;
}

.solutionItem h3 {
    font-size: 30px;
}

.solutionItem p {
    font-size: 20px;
    color: #666;
}

.solution {
    position: relative;
}

.tehnologies {
    margin-top: 80px;
    margin-bottom: 150px;
    position: relative;
}

.tehnologiesContainer {
    width: 80vw;
    margin: auto;
    padding-top: 20px;
    display: flex;
    gap: 15vw;
    min-height: 300px;
}

.tehnologiesContainer .item ul{
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}

.tehnologiesContainer .item ul li {
    margin-left: 0;
}

.tehnologiesContainer .item:first-of-type {
    margin-left: 40px;
}

.serpantOne {
    position: absolute;
    bottom: -160px;
    right: -75px;
}

.serpantTwo {
    position: absolute;
    right: -75px;
    top: -100px;
}

.serpantFour {
    position: absolute;
    bottom: -160px;
    left: -75px;
    transform: rotateY(180deg);
}

.contact-wrapper {
    padding-top: 30px;
}
