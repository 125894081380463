.logo{
  position: relative;
}
.logo img{
  position: absolute;
  height: 70px;
  top: -35px;
  left: -110px;
}
.menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.menu-toggle svg {
  width: 24px;
  height: 24px;
  display: none;
}

.nav {
  position: fixed;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 12px 48px;
  box-sizing: border-box;
  top: 0;
  background-color: white;
}
.nav.additional {
  justify-content: space-between;
}
.nav.additional .logo{
  margin-left: 200px;
}
.links{
  display: flex;
  flex-direction: row;
}

.section {
  cursor: pointer;
  color: #1D1D1B;
  font-family: 'Arial';
  font-size: 20px;
  margin-top: 30px;
  margin-left: 10px;
}



@media (max-width: 1300px) {
  .menu-toggle svg {
    width: 40px;
    height: 40px;
    display: block;}

  .logo img {
    position: absolute;
    height: 50px;
    top: -25px;
    left: 0;}

  .links{
    display: none;}

  .nav{
    margin: 0;
    position: fixed;
    top: 0;
    width: 100%;
    display: grid;
    grid-template-columns: auto 48px;
    justify-content: normal;
    padding: 24px;
    z-index: 100;
    background-color: white;
    border-radius: 0;}

  .links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px; 
    right: 20px; 
    background-color: var(--clr-grey-10);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    overflow: hidden;}

  .open {
      display: flex;
      width: 100%;
      right: 0;
      height: 100vh;
      align-items: flex-start;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;}

  .link{
    color: #1D1D1B;
    font-family: 'Arial';
    font-size: 20px;
    margin-top: 30px;
    margin-left: 10px;
  }

  .section {
    cursor: pointer;
    color: #1D1D1B;
    font-family: 'Arial';
    font-size: 20px;
    margin-top: 30px;
    margin-left: 10px;
  }
}
@media (max-width: 425px) {
  .nav{
    padding: 10px;}
}