.ProjectCard {
    min-width: 600px;
    cursor: pointer;
    padding: 20px 40px;
    border-radius: 20px;
    border: 1px solid black;
}

.ProjectCard img {
    height: 200px;
    margin-bottom: 20px;
}

.ProjectCard__tags {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.ProjectCard__title {
    font-size: 25px;
}

.ProjectCard__tags span {
    background-color: #7177ff;
    color: white;
    padding: 2px 10px;
    height: fit-content;
    border-radius: 8px;
}

.ProjectCard p {
    color: #666;
}