.BigLetters{
    color: white;
    font-size: 5vh;
}

.contact-wrapper {
    padding-top: 120px;
}

.ContactUs{
    position: relative;
    background: #1D1D1B;
    overflow: hidden;

}
.TopContent {
    display: flex;
    width: 82vw;
    top: 14vh;
    position: relative;
    align-items: center;
    justify-content: space-evenly;
}

.container h2{
    color: #111; 
    margin-bottom: 45px; 
    line-height: 1em; 
    font-weight: 500;
    padding-left: 10px; 
    border-left: 5px solid #e91e63; 
}
.inputBox{
    position: relative;
    width: 300px; 
    height: 46px; 
    margin-bottom: 35px; 
}
.inputBox:last-child{
    margin-bottom: 0; 
}
.inputBox input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
    padding: 10px;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
    font-size: 16px;
    font-weight: 300;
    border: none;
    border-bottom: 2px solid white;
    color: white;
    width: 30vw;
}
.inputBox span{
    position: absolute;
    top: 1px;
    left: 1px;
    display: inline-block; 
    padding: 10px;
    font-size: 16px;
    color: #CCCCCC;
    font-weight: 300;
    transition: 0.5s; 
    pointer-events: none;
}
.inputBox input:focus ~ span,
.inputBox input:valid ~ span{
    font-size: 1.5vh;
    color: #b8b8b8;
    transform: translateX(-0.7vw) translateY(-3vh); 
}
.form{
    margin-left: 10vw;
}
.btn{
    font-weight: 500;
    font-size: 2vh;
    text-align: center;
    color: #FFFFFF;
    background: #0081F8;
    border: none;
    width: 10vw;
    border-radius: 20px;
    padding: 1.5vh;
    top: 3vh;
    position: relative;
}
.OurContacts{
    width: 90vw;
    margin: auto;
    margin-top: 30vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.OurContactsBottom{
    width: 82vw;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.SocialNetworks svg{
    margin-right: 0.5vw;
    
}
.Mail{
    color: white;
    font-size: 21px;
}
.Contact_line{
    width: 82vw;
    border: 1px solid #2F2F2F;
    
}
.BackToTop, .Copyright{
    color: #CCCCCC;
    font-size: 1.5vh;
}
.BackToTop a{
    color: #CCCCCC;
    text-decoration: none;
}
.Footer{
    display: none;
    position: absolute;
    color: white;
    opacity: 0.1;
    font-size: 26vh;
    line-height: 26vh;
    height: 16.5vh;
    overflow: hidden;
    bottom: 0;
    margin: auto;
    margin-left: 14vw;
}
@media screen and (max-width: 1024px) {
    .Footer {
        font-size: 170px;
        line-height: 220px;
        height: 130px;
        margin-left: 100px; }
        
    .btn {
        font-size: 22px;
        width: 206px;
        border-radius: 20px;
        padding: 10px;
        top: 3vh;  }
    .TopContent{
        margin-left: 100px;}
    .contact-wrapper {
        padding-top: 60px;
    }
        
}

@media screen and (max-width: 768px) {
    .Footer {
        font-size: 145px;
        line-height: 190px;
        height: 121px;
        margin-left: 50px;
    }
    .BigLetters {
        color: white;
        font-size: 4vh;
    }
    .btn {
        font-size: 20px;    
        width: 178px;
        border-radius: 20px;
        padding: 10px;
        top: 2vh;
    }
}

@media screen and (max-width: 425px) {
    .Footer {
        font-size: 74px;
        line-height: 189px;
        height: 111px;
        margin: 0;
        position: relative;
        text-align: center;}
    .BigLetters {
        font-size: 53px;
        margin-left: 10px;}
    .TopContent{
        width: 82vw;
        top: 50px;
        position: relative;
        align-items: center;
        justify-content: space-evenly;
        margin: 0;
        flex-direction: column;}
    .inputBox input{
        width: 300px;}
    .form {
        margin-left: 10px;
        margin-top: 30px;}
    .App {
        height: 960px;}
    .SocialNetworks svg {
        margin-right: 0.5vw;
        width: 40px;}
    .OurContacts{
        margin-top: 190px;}
    .BigLetters {
    font-size: 53px;
    margin-left: 10px;}
}

@media screen and (max-width: 375px) {
    .Mail {
        color: white;
        font-size: 20px;}
    .Footer {
        font-size: 60px;
        line-height: 189px;
        height: 111px;
        margin: 0;}
}