@import url('https://fonts.cdnfonts.com/css/just-sans');
body {
  margin: 0;
  font-family: 'JUST Sans', sans-serif;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* #about {
  padding-top: 80px;
} */

@media screen and (max-width: 1250px) {
  #about {
    padding-top: 40px;
  }
}

@media screen and (max-width: 800px) {
  #about {
    padding-top: 20px;
  }
}

@media screen and (max-width: 570px) {
  #about {
    padding-top: 0px;
  }
}
.Project_container{
background: #E9FBFF;
padding: 4vh;
}
 