.service_title_text{
    font-size: 3vh;
    font-weight: 600;
    margin-bottom: 2vh;
}
.content_service{
    width: 90%;
    margin-left: 2vw;
    font-size: 2vh;
}
.service_title{
    display: flex;
}
.counter{
    margin-right: 0.5vw;
    color: blue;
}

@media screen and (max-width: 768px) {
    .content_service {
        margin-bottom: 7vh;
        width: 100%;
        margin-left: 2vw;
        font-size: 2vh;}
}

@media screen and (max-width: 425px) {
    .service_title_text {
        font-size: 22px;
        margin-bottom: 15px;
    }
        .content_service {
        margin-bottom: 7vh;
        width: 100%;
        margin-left: 2vw;
        font-size: 15px;
    }
}

@media screen and (max-width: 375px) {
    .service_title_text {
        font-size: 19px;
        margin-bottom: 10px;}
}
