.square_container {
    position: relative;
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 20px;
    padding: 50px;
    overflow: hidden;
    color: #333;
}

.square_container.active {
    height: 40vh;
}

.content{
    opacity: 0;
    transform: translateY(200px);
    transition: all .5s ease;
    font-size: 1.2em;
    line-height: 1.5;
    text-align: justify;
    width: 90%;
}
.content.active {
    opacity: 1;
    transform: translateY(0);
}

.square_title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #444;
}

.square_icon {
    width: 50px;
    height: 50px;
}

.square_icon{
    width: 6vh;
    height: 6vh;
    margin-bottom: 1vh;
}

.square_title {
    font-size: 25px;
    font-weight: 500;
}

.square_arrow{
    width: 100%;
    justify-content: right;
    display: flex;
    margin-top: 7vh;
}

.arrow {
    transition: transform 0.3s ease;
} 

.rotated {
    transform: rotate(180deg);
}

.square_arrow_container{
    width: 6vh;
    height: 6vh;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 2vh;
    right: 2vw;
    z-index: 0;
}
.SquareTitleContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
}

