.long_container{
    height: 4vh;
    padding: 2vh;
    border: 1px solid black;
    border-radius: 10px;
    width: 78vw;
    margin: auto;
    margin-bottom: 15px;
    transition: all 1s ease;
}

.flex{
    display: flex;
    cursor: pointer;
}
.advantages_line{
    width: 76vw;
    border: 1px solid #202020;
    margin-bottom: 2vh;
    margin-top: 2vh;
}
.contentl{
    opacity: 0;
    transition: all .2s ease;
    transition-delay: 0ms;
    font-size: 1.2em;
    line-height: 1.5;
    text-align: justify;
    width: 90%;
}
.contentl.active {
    opacity: 1;
    transition-delay: 500ms;
}
.long_container_text{
    padding-top: 6px;
    transition: all 1s ease;
    font-size: 2vh;
    margin-left: 2vw;
    width: 95%;
}
.long_container.active {
    height: 16vh;
}

@media screen and (max-width: 1150px) {
    .long_container.active {
        height: 22vh;
    }
}
@media screen and (max-width: 1150px) {
    .long_container_text {
        font-size: 15px;
        margin-left: 2vw;
        width: 95%;
        padding: 0;
    }
}



  