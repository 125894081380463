body {
    font-family: 'Arial', sans-serif; 
}

.how-we-work-container {
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
}

.stage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;
    position: relative;
}

.stage .icon {
    width: 110px;
    height: 110px;
    margin-bottom: 20px;
    background: #459aff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
}

.stage h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.stage p {
    font-size: 20px;
    color: #666;
    max-width: 400px;
    text-align: left;
    margin: 0 auto;
    line-height: 25px;
}


.stage:first-child::before{
    left: 150%;
    content: '';
    position: absolute;
    width: 200%;
    height: 10px;
    background-color: #333;
    transform: translateX(-50%);
    top: 55px;
    z-index: -1;
}
/* .stage:last-child::before {
    left: 0;
} */

.stage-number {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #333;
    top: -60px; 
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}
.Blocks {
    display: grid;
    grid-template-columns: auto auto auto;
}
.Blocks::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #333;
    left: 50%;
    transform: translateX(-50%);
    top: -30px;
}
@media screen and (max-width: 1300px) {
    .stage p {
        font-size: 20px;
        color: #666;
        max-width: 350px;
        text-align: left;
        margin: 0 auto;
        line-height: 24px;
    }
    
}
@media screen and (max-width: 1300px) {
    .stage p {
        font-size: 20px;
        color: #666;
        max-width: 400px;
        text-align: left;
        margin: 0 auto;
        line-height: 25px;
    }
    .Blocks {
        display: grid;
        grid-template-columns: auto;
    }
    .stage::before {
        content: '';
        position: absolute;
        background-color: #333;
        left: 50%;
        transform: translateX(-50%);
        top: 55px;
        z-index: -1;
        width: 8px;
        height: 100%;
        left: 45%;
        display: none;
        
    }
    .stage {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin: 20px 0;
        position: relative;
        justify-content: space-around;
    }
}
@media screen and (max-width: 1300px) {
   
    .stage {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px 0;
        position: relative;
        justify-content: space-around;
    }
    .stage .icon {
        width: 70px;
        height: 70px;
        margin-bottom: 10px;
        background: #459aff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        justify-content: center;
    }
}
@media screen and (max-width: 450px) {
   
    .stage h3 {
        font-size: 18px;
        color: #333;
        margin-bottom: 20px;
    }
    .stage p {
        font-size: 15px;
        width: 82vw;
    }
}