.process_grid {
    display: grid;
    grid-gap: 15px;
}
.flex_content {
    display: flex;
    width: 80vw;
    margin: auto;
}
.process_flex {
    transition: all 0.4s cubic-bezier(.86, .01, .15, .99);
    display: flex;
    padding: 2vh;
    border: 1px solid black;
    border-radius: 10px;
    align-items: center;
    justify-content: space-around;
    transform: perspective(1px) translateZ(0);
}




.process_flex:hover {
        color: black;
    }


.process_flex::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        right: 50%;
        bottom: 0;
        opacity: 0;
        transition: 1s all ease;
        box-sizing: border-box;
    }


.process_flex:hover::before {
        border-radius: 4px;
        transition: 1s all ease;
        left: 0;
        right: 0;
        background: rgba(154, 236, 254, 0.45);
        opacity: 1;
        z-index: -1;
    }

.process_counter {
    font-size: 20px;
    width: 5vw;
    text-align: center;
}

.process_text {
    width: 28vw;
    font-size: 20px;
}

.read_btn {
    width: 12vw;
    text-align: center;
    text-decoration: underline;
    font-size: 17px;
    cursor: pointer;
}

.selected{
    transition: all .3s ease-in-out;
    background-color: #9AECFE;
}


@media screen and (max-width: 425px) {
   .flex_content {
        display: flex;
        /* margin: 20px; */
        flex-direction: column;}

    .info_con {
        transition: all .3s ease-in-out;
        width: 95%;
        border: 1px solid black;
        border-radius: 10px;
        padding: 1vh;
        font-size: 1.8vh;
        margin: 0;
        margin-top: 7px; }

    .process_flex {
        padding: 20px;}

    .process_text {
        width: 60%; }
}
