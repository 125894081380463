    .Services {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 82vw;
        margin-left: 9vw;
    }

    .show {
        opacity: 1;
        filter: blur(0);
        transform: translateX(0);
    }

    @media screen and (max-width: 768px) {
        .Services {
            grid-template-columns: repeat(1, 1fr);
            width: 82%;
            margin: auto;
        }
    }

    @media screen and (max-width: 425px) {
        .content_service {
            margin-bottom: 45px;
            width: 100%;
            font-size: 15px;
        }
    }

    .service:first-child {
        margin-top: 50px;
    }

    .service {
        transition: transform 0.3s, box-shadow 0.3s;
    }

    .service:nth-child(odd) {
        padding: 40px;
        box-shadow: -20px 20px 20px 10px #2ce8ff7d;
    }

    .service:nth-child(even) {
        padding: 40px;
        box-shadow: 20px 20px 10px 3px #4d84ff80;
    }

    .service:last-child {
        margin-bottom: 100px;
    }

    .service:nth-child(2n) {
        margin-left: 30px;
    }

    .service:hover {
        transform: scale(1.05);
        box-shadow: -20px 20px 20px 10px #0000007d;
    }

    .solid_line {
        position: relative;
    }
    .solid_line:last-child{
        display: none;
    }
    .solid_line::before,
    .solid_line::after {
        content: "";
        position: absolute;
        background: transparent;
        
    }

    .solid_line:nth-child(odd)::before,
    .solid_line:nth-child(odd)::after {
        border: none;
        border-color: #000bff;
    }

    .solid_line:nth-child(odd)::before {
        top: 50%;
        right: 0;
        width: 300px;  
        border-bottom: 70px solid #86f2ff;
        transform: translateY(-50%);
        border-top-left-radius: 60px;
    
    }

    .solid_line:nth-child(odd)::after {
        top: 45%;
        right: 194px;
        width: 1px;
        height: 50%;
        border: none;
        transform: translateX(-50%);
        border-left: 70px solid #86f2ff;
        border-top-left-radius: 60px;
    }

    .solid_line:nth-child(even)::before {
        top: 50%;
        left: 0;
        width: 300px;  
        border: none;
        border-bottom: 70px solid #7177ff;
        transform: translateY(-50%);
        border-top-right-radius: 60px;
        
    }

    .solid_line:nth-child(even)::after {
        top: 50%;
        left: 265px;
        height: 50%; 
        border-left: 70px solid #7177ff;
        transform: translateX(-50%);
        border-top-right-radius: 37px;
        
    }
    .service-appear {
        animation: serviceAnimation 0.3s forwards;
    }

    @keyframes serviceAnimation {
        from {
            transform: scale(0.8);
            opacity: 0;
        }
        to {
            transform: scale(1);
            opacity: 1;
        }
    }
    @media screen and (max-width: 800px) {
        .service:nth-child(odd), .service:nth-child(even) {
            padding: 1rem ;
            margin: 0;
            box-shadow: none;
        }
        .solid_line, .white_space{
            display: none;
        }
        .service:hover {
            box-shadow: none;
        }
        
    }
    @media screen and (max-width: 600px) {
        .service:nth-child(odd), .service:nth-child(even) {
            padding: 0 ;
            margin: 0;
        }
        
        
    }


