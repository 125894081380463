.Title{
    position: relative;
    /* margin-top: 130px; */
}
.zaglav{
    position: relative;
    left: 30%;
    font-size: 40px;
    padding-top: 50px;
    font-weight: 500;
}

.text_container{
    width: 47%;
}
.line{
    width: 82%;
    border: 1px solid black;
    margin: 20px 9%;
}

@media screen and (max-width: 1024px) {
    .zaglav {
        font-size: 30px;
        padding-top: 40px;
    }
    .line{
        margin: 15px 9%;
    }
}

@media screen and (max-width: 768px) {
    .zaglav {
        font-size: 25px;
        padding-top: 30px;
    }
    .line{
        margin: 10px 9%;
    }
}

@media screen and (max-width: 425px) {
    .zaglav {
        font-size: 20px;
        padding-top: 20px;
    }

    .line{
        margin: 10px 9%;
    }
}

@media screen and (max-width: 375px) {
    .zaglav {
        font-size: 20px;
        padding-top: 10px;
    }
    .line{
        margin: 10px 9%;
    }
}