.square {
    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    gap: 15px;
    transition: all 1s ease;
    width: 80vw;
    margin: auto;
}
@media screen and (max-width: 1024px) {
    .square{
        grid-template-columns: repeat(1, 1fr);
    }
    .square_container {
        margin-top: 20px;
        padding: 25px;
       
    }
}


