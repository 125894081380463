.img {
    width: 25vw;
    border-radius: 40px;
    margin-left: 10vw;
}
.About{
    display: flex;
}
.text_about_container{
    position: relative;
    left: 3vw;
    top: 2vh;
    width: 44vw;
}
.about_text{
    font-size: 2vh;
}
.btn_contact_us{
    font-weight: 500;
    font-size: 2vh;
    text-align: center;
    color: #FFFFFF;
    background: #0081F8;
    border: none;
    width: 10vw;
    border-radius: 20px;
    padding: 1.5vh;
    top: 3vh;
    position: relative;
    width: 250px;
}

@media screen and (max-width: 1024px) { 
    .About {
    flex-direction: column;
    align-items: center;
    min-height: 730px;}

    .img_container{
        text-align: center;}
    
    .img{
    width: 60%;
    border-radius: 40px;
    margin-left: 0;
    }
    .text_about_container {
    position: relative;
    top: 20px;
    width: 82%;
    height: 240px;}

    .about_text {
    font-size: 20px;}
    
    .btn_contact_us {
        width: 220px;
    }
}

@media screen and (max-width: 768px) {
    .About {
        flex-direction: column;
        align-items: center;
        min-height: auto}
    .hidden_text{
        display: none;}

}

@media screen and (max-width: 425px) {
   .about_text {
        font-size: 15px;}

    .btn_contact_us {
        width: 45%;
        font-size: 15px;}

    .img {
        width: 45%;}
}

@media screen and (max-width: 375px) {
    .btn_contact_us {
        width: 50%;
        font-size: 12px;
        top: 10px;}

    .img {
        width: 45%;}

        
}