.ProjectsGrid {
    overflow-x: scroll;
    display: flex;
    gap: 40px;
    width: 80vw;
    margin: auto;
    padding-top: 20px;
}

.ProjectsGrid::-webkit-scrollbar {
    display: none;
}

.ProjectsGrid {
    -ms-overflow-style: none;
    scrollbar-width: none;
}