.PartnersGrid {
    display: flex;
    align-items: center;
    width: 80vw;
    margin: auto;
    gap: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
}
.PartnerLogo {
    max-width: 90%;
    max-height: 200px;
}

.PartnerContainer {
    background-color: #2F2F2F;
    width: 100%;
    height: 30vh;
    min-width: 270px;
    border-radius: 20px;
    margin-left: 20px;
    margin: 20px 0;
    position: relative;
}
.CountryLogo{
    position: absolute;
    width: 13%;
    bottom: 0;
    right: 0;
    border-bottom-right-radius: 10px;
}

.PartnerLogoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}
.PartnerTitle {
    display: flex;
    position: absolute;
    color: white;
    font-size: 2vh;
    justify-content: center;
    align-items: center;
    width: 100%;
    opacity: 0.7;
    bottom: 5%;
}
.PartnersGrid::-webkit-scrollbar {
    display: none; /* Для Safari и Chrome */
}

.PartnersGrid {
    -ms-overflow-style: none;  /* Для IE и Edge */
    scrollbar-width: none; /* Для Firefox */
}

@media screen and (max-width: 1024px){
    .PartnersGrid{
        grid-template-columns: repeat(3, 1fr);
    }
    .PartnersGrid > :nth-child(5),
    .PartnersGrid > :nth-child(6) {
        grid-column: span 1;
    }
}

@media screen and (max-width: 900px){
    
    .PartnerImg {
        max-height: 10vh;
        max-width: 200px;
    }
}

@media screen and (max-width: 500px){
    
    .PartnerContainer {
        min-width: 220px;
    }
}